/**
 * @prettier
 */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { setInstanceID } from '../screens/actions/configActions';
import i18n from '../i18n';
import LanguageSelectInput from './LanguageSelectInput';

const Header = ({branding, showHeader, showLanguageSelect}) => {
    const logo = branding?.data.company_branding.logo || null;
    if (!showHeader) return null;
    return (     
        <header className='header-container'>
            {logo ? (
                <div>
                    <img
                        alt='Company Logo'
                        className='logo custom-logo'
                        src={`data:image/png;base64,${logo}`}
                    />
                </div>
            ) : (
                <div className='logo default-logo' />
            )}
            { showLanguageSelect && (
                <LanguageSelectInput
                    isLanguageChange={true}                               
                    elementIdToFocus={i18n.language}
                />)}
        </header>)         
};

function mapStateToProps(state) {
    return {
        instanceID: state.config.instanceID,
        branding: state.config.profile,
        showHeader: state.config.showHeader,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setInstanceID }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);