const PassportIcon = ({fill}) => (
        <svg width="51" height="51"  fill={fill} style={{position:'relative', left: '-10px'}}>
            <path d="M14.2699 44.625H40.1666C41.3336 44.625 42.2831 43.6787 42.2831 42.5156V8.48438C42.2831 7.32126 41.3336 6.375 40.1666 6.375H14.2699C13.1045 6.375 12.1564 7.32126 12.1564 8.48438V42.5156C12.1564 43.6787 13.1045 44.625 14.2699 44.625ZM40.1666 7.57031C40.6745 7.57031 41.0878 7.98035 41.0878 8.48438V42.5156C41.0878 43.0196 40.6745 43.4297 40.1666 43.4297H14.2699C13.7636 43.4297 13.3517 43.0196 13.3517 42.5156V8.48438C13.3517 7.98035 13.7636 7.57031 14.2699 7.57031H40.1666Z" />
            <path d="M10.8304 44.625H14.2699C14.6 44.625 14.8676 44.3574 14.8676 44.0273C14.8676 43.6973 14.6 43.4297 14.2699 43.4297C13.7636 43.4297 13.3517 43.0196 13.3517 42.5156V8.48438C13.3517 7.98035 13.7636 7.57031 14.2699 7.57031C14.6 7.57031 14.8676 7.30274 14.8676 6.97266C14.8676 6.64258 14.6 6.375 14.2699 6.375H10.8304C9.66501 6.375 8.7169 7.32126 8.7169 8.48438V42.5156C8.7169 43.6787 9.66501 44.625 10.8304 44.625ZM12.3653 43.4297H10.8304C10.3241 43.4297 9.91221 43.0196 9.91221 42.5156V8.48438C9.91221 7.98035 10.3241 7.57031 10.8304 7.57031H12.3653C12.2315 7.847 12.1564 8.15709 12.1564 8.48438V42.5156C12.1564 42.8429 12.2315 43.153 12.3653 43.4297Z" />
            <path d="M27.2182 33.252C28.7342 33.252 30.205 32.9562 31.5901 32.373C32.9276 31.8098 34.1287 31.0036 35.1601 29.9768C36.1916 28.95 37.0015 27.7541 37.5674 26.4224C38.1534 25.0432 38.4506 23.5786 38.4506 22.0693C38.4506 20.5594 38.1534 19.0944 37.5674 17.7151C37.0015 16.3833 36.1916 15.1874 35.1601 14.1607C34.1287 13.1342 32.9276 12.3282 31.59 11.7652C30.2051 11.1823 28.7342 10.8867 27.2183 10.8867C25.703 10.8867 24.2325 11.1823 22.8477 11.7652C21.5102 12.3282 20.3091 13.1342 19.2776 14.1606C18.2458 15.1874 17.4356 16.3832 16.8696 17.715C16.2832 19.0944 15.986 20.5594 15.986 22.0693C15.986 23.5785 16.2832 25.0432 16.8696 26.4224C17.4357 27.7541 18.2458 28.95 19.2775 29.9769C20.3091 31.0036 21.5102 31.8097 22.8476 32.373C24.2325 32.9562 25.703 33.252 27.2182 33.252ZM27.2182 12.082C32.7527 12.082 37.2552 16.5623 37.2552 22.0693C37.2552 27.5764 32.7527 32.0566 27.2182 32.0566C21.6838 32.0566 17.1812 27.5764 17.1812 22.0693C17.1812 16.5623 21.6838 12.082 27.2182 12.082Z" />
            <path d="M27.2183 33.252C28.1197 33.252 28.9851 32.9286 29.7903 32.2907C30.5281 31.7062 31.1851 30.8799 31.7431 29.8348C32.8558 27.7505 33.4686 24.9927 33.4686 22.0693C33.4686 19.1449 32.8558 16.3867 31.7431 14.3028C31.1851 13.2579 30.5281 12.4318 29.7902 11.8475C28.985 11.21 28.1197 10.8867 27.2183 10.8867C26.3168 10.8867 25.4515 11.21 24.6463 11.8475C23.9084 12.4318 23.2514 13.2579 22.6934 14.3028C21.5807 16.3867 20.9679 19.1449 20.9679 22.0693C20.9679 24.9927 21.5807 27.7505 22.6934 29.8348C23.2514 30.8799 23.9084 31.7062 24.6462 32.2907C25.4515 32.9286 26.3168 33.252 27.2183 33.252ZM27.2183 12.082C28.4976 12.082 29.7301 13.0707 30.6887 14.8658C31.7105 16.7795 32.2733 19.3378 32.2733 22.0693C32.2733 24.7998 31.7105 27.3577 30.6886 29.2718C29.7299 31.0676 28.4974 32.0566 27.2183 32.0566C25.9391 32.0566 24.7066 31.0676 23.7479 29.2718C22.726 27.3577 22.1632 24.7998 22.1632 22.0693C22.1632 19.3378 22.726 16.7795 23.7478 14.8658C24.7064 13.0707 25.9389 12.082 27.2183 12.082Z" />
            <path d="M27.2183 33.2402C27.5483 33.2402 27.8159 32.9726 27.8159 32.6425V11.4844C27.8159 11.1543 27.5483 10.8867 27.2183 10.8867C26.8882 10.8867 26.6206 11.1543 26.6206 11.4844V32.6425C26.6206 32.9726 26.8882 33.2402 27.2183 33.2402Z" />
            <path d="M27.2182 28.2936C30.1546 28.2936 32.9246 27.6835 35.018 26.5757C36.0674 26.0204 36.8971 25.3665 37.4841 24.6322C38.1254 23.8301 38.4506 22.9679 38.4506 22.0696C38.4506 21.1707 38.1254 20.3082 37.4842 19.5059C36.8972 18.7715 36.0674 18.1176 35.0179 17.5624C32.9252 16.4554 30.1552 15.8457 27.2182 15.8457C24.2824 15.8457 21.5128 16.4554 19.4197 17.5624C18.37 18.1176 17.54 18.7715 16.9528 19.5058C16.3112 20.3082 15.9859 21.1707 15.9859 22.0696C15.9859 22.9679 16.3112 23.8301 16.9528 24.6323C17.5401 25.3665 18.37 26.0204 19.4196 26.5757C21.5134 27.6835 24.283 28.2936 27.2182 28.2936ZM27.2182 17.041C29.9638 17.041 32.5353 17.6014 34.459 18.619C36.2622 19.5729 37.2552 20.7984 37.2552 22.0696C37.2552 23.3399 36.2622 24.5649 34.4589 25.5192C32.5347 26.5375 29.9632 27.0983 27.2183 27.0983C24.4744 27.0983 21.9033 26.5375 19.9787 25.5192C18.1747 24.5647 17.1812 23.3397 17.1812 22.0696C17.1812 20.7985 18.1747 19.5731 19.9786 18.619C21.9026 17.6014 24.4737 17.041 27.2182 17.041Z" />
            <path d="M16.5835 22.667H37.8411C38.1711 22.667 38.4387 22.3994 38.4387 22.0693C38.4387 21.7393 38.1711 21.4717 37.8411 21.4717H16.5836C16.2535 21.4717 15.9859 21.7393 15.9859 22.0693C15.9859 22.3994 16.2534 22.667 16.5835 22.667Z" />
            <path d="M16.8998 36.6807H37.5367C37.8668 36.6807 38.1344 36.4131 38.1344 36.083C38.1344 35.7529 37.8668 35.4854 37.5367 35.4854H16.8998C16.5697 35.4854 16.3021 35.7529 16.3021 36.083C16.3021 36.4131 16.5697 36.6807 16.8998 36.6807Z" />
            <path d="M20.8003 40.1133H33.6364C33.9664 40.1133 34.234 39.8457 34.234 39.5156C34.234 39.1855 33.9664 38.918 33.6364 38.918H20.8003C20.4702 38.918 20.2026 39.1855 20.2026 39.5156C20.2026 39.8457 20.4702 40.1133 20.8003 40.1133Z" />
        </svg>)

export default PassportIcon;
